


























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FaqBox extends Vue {
  isFaqOpen = false;
  faqID = '';

  //FAQのID出力用（他のFAQと被らないように乱数）
  generateRandomString = () => {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 8; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return 'Faq_' + result;
  };

  //マウント時、ID設定
  mounted() {
    this.faqID = this.generateRandomString();
  }
}
